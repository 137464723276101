// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]7.0-rc-11489(e4057b9e25)-C02/04/2025-17:16:14-B02/04/2025-17:30:11' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]7.0-rc-11489(e4057b9e25)-C02/04/2025-17:16:14-B02/04/2025-17:30:11",
  branch: "master",
  latestTag: "7.0-rc",
  revCount: "11489",
  shortHash: "e4057b9e25",
  longHash: "e4057b9e25a63cb3e94f5ff4a7791bf49882756d",
  dateCommit: "02/04/2025-17:16:14",
  dateBuild: "02/04/2025-17:30:11",
  buildType: "Ansible",
  } ;
